<script>
import newsletterService from '@/services/newsletter.service'

export default {
	name: 'NewsletterSubscribers',
	data() {
		return {
			items: [],
			headerFields: [
        "__slot:checkboxes",
        {
          name: "id",
          label: "Id"
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "tags",
          label: this.$tc('tag', 2),
        },
      ],
		}
	},
	mounted() {
		this._getSubscribersList()
	},
	methods: {
		_getSubscribersList() {
			newsletterService.getSubscribersList().then(res => {
				this.items = res.data
			})
		},
	},
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <BaseListTable
        :items="items"
        :header-fields="headerFields"
        type="subscribers"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'
</style>
