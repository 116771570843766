import { get } from '@/services/api.service';

const API_URL = '/newsletter';

class NewsletterService {
  getSubscribersList() {
    return get(`${API_URL}`)
      .then(res => res)
  }
}

export default new NewsletterService();